import React from "react";
import PacmanLoader from "@bit/davidhu2000.react-spinners.pacman-loader";
import "./rank.css";

const Loading = () => {
  return (
    <div className="center">
      <PacmanLoader
        size={60}
        color="#ffffff"
        css={{ 
          width: "260px !important", 
          height: "130px !important",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, 40vh)",
        }}
      />
    </div>
  );
};

export default Loading;
