import React, { useState, useEffect } from "react";
import Rank from "./components/Rank";
import Loading from "./components/Loading";
import moment from "moment"

const stores = {
  ecoRepo: "78pWxTbPHR",
  ecoAten: "Yae9HI7J6l",
  ecoPada: "H1xrLH6llu",
  ecoAcog: "GG9wOs7CBf",
  ecoGera: "iMGeWnmUMf",
  alle: "",
};

const getStoreName = () => {
  var selectedStore = window.location.pathname.split("/")[1];
  if (selectedStore) {
    if (stores[selectedStore] || selectedStore === "alle") {
      window.localStorage.setItem("store", selectedStore);
      return selectedStore;
    } else {
      window.localStorage.setItem("store", "ecoRepo");
      return "ecoRepo";
    }
  } else {
    if (window.localStorage.getItem("store"))
      return window.localStorage.getItem("store");
    else {
      window.localStorage.setItem("store", "ecoRepo");
      return "ecoRepo";
    }
  }
};

function App() {
  const params = Object.fromEntries(new URLSearchParams(window.location.search).entries());
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [storeName, setStoreName] = useState("");
  const [workersRank, setWorkersRank] = useState([]);

  useEffect(() => {
    var startDate = moment().startOf("month")
    var endDate = moment().endOf("month")
    if (params.mon) {
      startDate.add(Number(params.mon), "month")
      endDate.add(Number(params.mon), "month")
    }


    setLoading(true);
    fetch(`${process.env.REACT_APP_PARSE_URL}/functions/getStoreRanking?id=${stores[getStoreName()]}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
      {
        headers: {
          "X-Parse-Application-Id": process.env.REACT_APP_PARSE_ID,
          "X-Parse-REST-API-Key": process.env.REACT_APP_REST_API_KEY,
        },
        method: "POST",
      }
    )
      .then((res) => res.json())
      .then(({ result }) => {
        const { store, topRank } = result;
        setStoreName(store?.name || "Geral");
        setWorkersRank(
          topRank.map(({ name, validRatings, photo }) => ({
            name,
            ratings: validRatings,
            photo: photo?.url || window.location.origin + "/no-picture.png",
          }))
        );
        setLoading(false);
      });
  }, []);

  return loading ? <Loading /> : <Rank workersRank={workersRank} />;
}

export default App;
