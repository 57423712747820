import React from "react";
import "./rank.css";
import logo from "../img/logo.png";

const getPhoto = (worker) => worker?.photo || "/no-picture.png";

const Rank = ({ workersRank }) => {
  const data = JSON.parse(JSON.stringify(workersRank)).slice(3);
  return (
    <div>
      <img src={logo} alt="logomarca avaliei" className="logo" />
      <div className="center">
        <div className="top3">
          <div className="two item">
            <div className="pos">2º</div>
            <div
              className="pic"
              style={{
                backgroundImage: "url(" + getPhoto(workersRank[1]) + ")",
              }}
            ></div>
            <div className="name">{workersRank[1].name.split(" ")[0]}</div>
            <div className="score">{workersRank[1].ratings}</div>
          </div>

          <div className="one item">
            <div className="pos">1º</div>
            <div
              className="pic"
              style={{
                backgroundImage: "url(" + getPhoto(workersRank[0]) + ")",
              }}
            ></div>
            {/* <img src={medal} className="medalOne" alt=""/> */}
            <div className="name">{workersRank[0].name.split(" ")[0]}</div>
            <div className="score">{workersRank[0].ratings}</div>
          </div>

          <div className="three item">
            <div className="pos">3º</div>
            <div
              className="pic"
              style={{
                backgroundImage: "url(" + getPhoto(workersRank[2]) + ")",
              }}
            ></div>
            <div className="name">{workersRank[2].name.split(" ")[0]}</div>
            <div className="score">{workersRank[2].ratings}</div>
          </div>
        </div>

        <div className="listContainer">
          <div className="list">
            {/* <h1 className="title2">
              Outras condecorações
            </h1> */}
            {data.map((worker, idx) => (
              <div key={idx} className="item">
                <div className="pos">{idx + 4}º</div>
                <div
                  className="pic"
                  style={{
                    backgroundImage: "url(" + getPhoto(worker) + ")",
                  }}
                ></div>
                <div className="name">{worker.name.split(" ")[0]}</div>
                <div className="score">{worker.ratings}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rank;
